import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import DInput from '@/components/DInput';
import Margin from '@/components/Margin';
import DButton from '@/components/DButton';
import { Contract, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { parseEther } from 'ethers/lib/utils';
import useApproved from '@/hooks/useApproved';
import { message } from 'antd';
import { useProvider } from '@/hooks/useProvider';
import useERC20Balance from '@/hooks/useERC20Balance';
import commifyBigNumber from '@/utils/commify';
import { useWeb3 } from '@/web3';
import { StakeRepository } from '../config';
import { StakeAbi } from '../abis/StakeAbi';

interface Props {
  stakeRepository: StakeRepository;
  visible: boolean;
  closeModal: () => void;
  refresh: () => void;
}

const WithdrawModal: FC<Props> = props => {
  const { visible, closeModal, refresh, stakeRepository } = props;

  const { stakeAddress } = stakeRepository;

  const [inputAmount, setInputAmount] = useState('');

  const { provider, signer } = useProvider();

  const { account } = useWeb3();

  const [stakedAmount, setStakedAmount] = useState(ethers.constants.Zero);

  useEffect(() => {
    if (!account || !provider) return;
    (async () => {
      const contract = new Contract(stakeAddress, StakeAbi, provider);
      const stakeAmount = await contract._balances(account);
      setStakedAmount(stakeAmount);
    })();
  }, [account, provider]);

  const withdraw = async () => {
    const contract = new Contract(stakeRepository.stakeAddress, StakeAbi, signer);
    const tx = await contract.withdraw(parseEther(inputAmount));
    await tx.wait();
    message.success('withdraw successfully');
    refresh();
    closeModal();
  };

  return (
    <>
      {!visible && null}
      {visible && (
        <ModalBackground>
          <Modal>
            <TitleRow>
              <span>Withdraw</span>
              <i className="iconfont icon-guanbi2" onClick={closeModal} />
            </TitleRow>
            <StakeWrapper>
              <Margin top={20} />
              <DInput
                value={inputAmount}
                onChange={setInputAmount}
                // maxAmount={}
              />
              <Margin top={6} />
              <div className="balance-text">Balance: {commifyBigNumber(stakedAmount)}</div>
              <DButton disabled={inputAmount === ''} onClick={withdraw}>
                Withdraw
              </DButton>
              <Margin top={40} />
            </StakeWrapper>
          </Modal>
        </ModalBackground>
      )}
    </>
  );
};

export default WithdrawModal;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const Modal = styled.div`
  margin: 112px auto 0;
  max-width: 530px;
  height: auto;
  background: var(--bg-black3);
  border-radius: 4px;
  text-align: center;
  padding: 30px 40px;
  z-index: 101;
  // overflow: scroll;
  .balance-text {
    width: 100%;
    text-align: left;
    margin-bottom: 14px;
  }

  @media (max-width: 768px) {
    margin: 20px auto 0;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-white3);
  font-weight: bold;

  span {
    font-size: 24px;
  }

  i {
    font-size: 20px;
    cursor: pointer;
    color: var(--text-white3);
  }
`;

const StakeWrapper = styled.div``;
