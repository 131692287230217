import styled from '@emotion/styled';
import { FC, useState } from 'react';
import robotLogo from '@/assets/pro-cloud/robot.png';
import tradingChart from '@/assets/pro-cloud/trading-chart.png';
import stakePanel from '@/assets/pro-cloud/stake-panel.png';
import liquidityPool from '@/assets/pro-cloud/liquidity-pool.png';
import Margin from '@/components/Margin';
import FlexRow from '@/components/FlexRow';
import discountPng from '@/assets/pro-cloud/discount.png';
import config from '@/config/config';
import PurchaseModal, { Discount } from './PurchaseModal';

const { CDN_BASE_URL } = config;

declare global {
  interface Window {
    invite_referrals: any;
    ir: any;
  }
}

const ProCloud: FC = () => {
  const [purchaseModalVisible, setPurchaseModalVisible] = useState(false);

  /* eslint-disable */
  let ir: any;
  ir =
    ir ||
    function () {
      (window.ir.q = window.ir.q || []).push(arguments);
    };
  let invite_referrals = window.invite_referrals || {};
  (function () {
    invite_referrals.auth = {
      bid_e: '0A8507DE2774EC088CA090120241CD0A',
      bid: '67154',
      t: '420',
      email: '',
      userParams: { fname: '' },
    };
    invite_referrals.async = false;
    let script = document.createElement('script');
    script.src = '//cdn.invitereferrals.com/js/invite-referrals-1.0.js';

    let entry: any = document.getElementsByTagName('script')[0];
    entry.parentNode.insertBefore(script, entry);
  })();
  /* eslint-enable */

  return (
    <ProCloudWrapper>
      <div className="content-area">
        <div className="left">
          <img src={robotLogo} alt="" className="logo" />
        </div>
        <div className="right">
          <div className="cloud-title">Duet Pro Cloud Ticket</div>
          <Margin top={6} />
          <SubTitle>Price</SubTitle>
          <Margin top={10} />
          <FlexRow>
            <PriceContent>$5000</PriceContent>
            <Margin left={12} />
            <img src={`${CDN_BASE_URL}/token/USDC.png`} alt="" className="stable-coin" />
            <Margin left={10} />
            <img src={`${CDN_BASE_URL}/token/USDT.png`} alt="" className="stable-coin" />
            <Margin left={10} />
            <img src={`${CDN_BASE_URL}/token/DAI.png`} alt="" className="stable-coin" />
          </FlexRow>
          <Margin top={8} />
          <FlexRow>
            <PriceContent>Or</PriceContent>
            <Margin left={12} />
            <PriceContent style={{ color: 'rgba(193, 69, 255, 1)' }}>
              500,000 $DUET
              <Discount>
                <img className="discount" src={discountPng} alt="" />
                <div className="discount-text">Discount 30%</div>
              </Discount>
            </PriceContent>
          </FlexRow>

          <Margin top={16} />
          <SubTitle>Total Supply</SubTitle>
          <Margin top={8} />
          <PriceContent>2,000</PriceContent>

          <Margin top={20} />
          <SubTitle>Intro</SubTitle>
          <Margin top={8} />
          <IntroductionText>
            Duet Pro Cloud represents a pioneering initiative designed to empower third parties with the ability to
            build upon our robust, highly-secure infrastructure. This unique opportunity allows our partners to
            concentrate on their core business operations while leveraging Duet Pro's sophisticated technology, as well
            as benefiting from shared trading liquidity and depth. Furthermore, partners can expect transparency and
            competitive fees in all transactions. Securing a subscription to Duet Pro Cloud is your first step towards
            embarking on this transformative journey.
          </IntroductionText>

          <Margin top={30} />
          <BuyNowButton onClick={() => setPurchaseModalVisible(true)}>
            <FlexRow style={{ height: '100%' }} direction="column" justifyContent="center">
              <div className="buy-now-text">Buy Now</div>
              <div className="small-text">Available Supply 2,000</div>
            </FlexRow>
          </BuyNowButton>
        </div>
      </div>

      <Margin top={100} />
      <CloudDetail>
        <div className="head-title">Duet Pro Cloud Intergration Detail</div>

        <Margin top={40} />
        <DetailFlexRow>
          <div className="sub-title">
            1. Experience robust security with a DEX platform customized to your preference.
          </div>
          <FlexRow justifyContent="center">
            <img src={tradingChart} alt="" className="demo-img" />
          </FlexRow>
        </DetailFlexRow>

        <Margin top={40} />
        <DetailFlexRow>
          <div className="sub-title">
            2. Craft a community liquidity pool, tapping into Duet Pro's shared liquidity and depth
          </div>
          <FlexRow justifyContent="center">
            <img src={liquidityPool} alt="" className="demo-img" />
          </FlexRow>
        </DetailFlexRow>
        <Margin top={40} />
        <DetailFlexRow>
          <div className="sub-title">
            3. Launch your tokens with accelerated recharge options, leveraging advanced staking solutions
          </div>
          <FlexRow justifyContent="center">
            <img src={stakePanel} alt="" className="demo-img" />
          </FlexRow>
        </DetailFlexRow>
      </CloudDetail>

      <PurchaseModal visible={purchaseModalVisible} closeModal={() => setPurchaseModalVisible(false)} />
      <div id="invtrflfloatbtn" />
    </ProCloudWrapper>
  );
};

export default ProCloud;

const CloudDetail = styled.div`
  width: 1350px;
  margin: 0 auto;
  color: white;

  .head-title {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #f4f4f4;
    text-align: center;
  }

  .sub-title {
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #f4f4f4;
  }

  .demo-img {
    width: 650px;
    margin: 0 auto;
  }
`;

const BuyNowButton = styled.div`
  background: rgba(157, 33, 214, 1);
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  padding: 8px;

  .buy-now-text {
    font-size: 22px;
  }
  .small-text {
    font-size: 12px;
  }
`;

const IntroductionText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const SubTitle = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(81, 100, 245, 1);
`;

const PriceContent = styled.span`
  font-family: Inter;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const DetailFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  .sub-title {
    text-align: center;
    margin-top: 30px;
    font-size: 20px;
    color: #f4f4f4;
  }
`;

const ProCloudWrapper = styled.div`
  background: black;

  .content-area {
    width: 1350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .left {
    img {
      width: 520px;
      height: 520px;
    }
  }

  .right {
    width: 700px;

    .cloud-title {
      color: white;
      font-family: Inter;
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: -4px;
    }

    .stable-coin {
      width: 30px;
      height: 30px;
    }
  }
`;
