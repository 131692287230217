import { useWeb3 } from '@/web3';

export const useProvider = () => {
  const { contractContainer } = useWeb3();

  return {
    provider: contractContainer?.context?.library,
    signer: contractContainer?.context?.library?.getSigner?.(),
  };
};
