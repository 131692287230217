import { ChangeEventHandler, CSSProperties, FC } from 'react';
import moduleStyles from './style.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  maxAmount?: string;
  isPercent?: boolean;
  width?: number;
  height?: number;
  style?: CSSProperties;
  disabled?: boolean;
}

const DInput: FC<Props> = props => {
  const { value, onChange, maxAmount, isPercent, width, height = 60, style, disabled = false } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const newValue = e.currentTarget.value;
    if (newValue === '') {
      onChange(newValue);
    }

    // 如果 isPercent，只接受两位小数的输入
    if (isPercent && !/^\d+(?:\.\d{0,2})?$/.test(newValue)) {
      return;
    }

    if (maxAmount && +newValue > +maxAmount) {
      onChange(maxAmount);
      return;
    }

    // 如果不是 percent，只接受18位小数的输入
    if (!isPercent && !/^\d+(?:\.\d{0,18})?$/.test(newValue)) {
      return;
    }

    onChange(newValue);
  };

  const setMax = () => {
    if (!maxAmount) return;
    onChange(maxAmount);
  };

  const widthStyle = {
    width: width ? `${width}px` : '100%',
  };

  return (
    <section className={moduleStyles.DInputWrapper} style={{ ...widthStyle, height: `${height}px` }}>
      <input
        className={moduleStyles.input}
        style={{ ...widthStyle, height: `${height}px`, ...style }}
        // width={width}
        type="number"
        value={value}
        onChange={handleChange}
        onWheel={e => e.currentTarget.blur()}
        placeholder="0"
        disabled={disabled}
      />
      {isPercent && <span className={moduleStyles.percent}>%</span>}
      {maxAmount && (
        <span className={moduleStyles.max} onClick={setMax}>
          MAX
        </span>
      )}
    </section>
  );
};

export default DInput;
