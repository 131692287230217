const config = {
  mainChainId: 42161,
  CHAIN_ID: 42161,
  NETWORK_NAME: 'Arbitrum One',
  WALLET_NETWORK_NAME_IN_CHAIN_REGISTRY: 'Arbitrum',

  THE_GRAPH_API_URL: 'https://api.thegraph.com/subgraphs/name/iuscript/duet-protocol-chapel',
  CDN_BASE_URL: 'https://duet.s3.amazonaws.com',
};

export default config;
