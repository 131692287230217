import commifyBigNumber from '@/utils/commify';
import { BigNumber, constants } from 'ethers';
import { useState } from 'react';
import styled from 'styled-components';
import CardStake from './components/CardStake';
import { stakeRepositories } from './config';

export interface IStakeItem {
  type: 'Token' | 'LP';
  tokenName: string;
  earnToken: {
    name: string;
  };
}

const ProCloudStake = () => {
  const [token1TotalSupply, setToken1TotalSupply] = useState(constants.Zero);
  const [token2TotalSupply, setToken2TotalSupply] = useState(constants.Zero);
  const [prices, setPrices] = useState([1000, 100000000]);

  const setTokenInfo = (index: number, supply: BigNumber) => {
    if (index === 0) {
      setToken1TotalSupply(supply);
    } else {
      setToken2TotalSupply(supply);
    }
  };

  const totalTvl = token1TotalSupply.mul(1000).add(token2TotalSupply.mul(200000000)).div(100000000);

  return (
    <ProCloudStakeWrapper>
      <div className="tvl-panel">CLOUD PROJECT TVL ${commifyBigNumber(totalTvl)}</div>
      <div className="panel-stake">
        {stakeRepositories.map((repository, index) => (
          <CardStake
            stakeTokenPrice={prices[index]}
            tokenIndex={index}
            setTokenInfo={setTokenInfo}
            key={index}
            stakeRepository={repository}
          />
        ))}
      </div>
    </ProCloudStakeWrapper>
  );
};

export default ProCloudStake;

const ProCloudStakeWrapper = styled.div`
  width: 80%;
  margin: 10px auto;
  padding: 30px 12px;
  .tvl-panel {
    background: burlywood;
    width: 94%;
    margin: 30px auto;
    padding: 20px 40px;
    font-size: 24px;
    color: #212121;
    border-radius: 12px;
    text-align: center;
  }
  .panel-stake {
    width: 100%;
    padding: 40px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
