export interface StakeRepository {
  stakeTokenName: string;
  stakeTokenAddress: string;
  rewardTokenName: string;
  rewardTokenAddress: string;
  stakeAddress: string;
}

export const stakeRepositories: StakeRepository[] = [
  {
    stakeTokenName: 'cDuck',
    stakeTokenAddress: '0x587d5a8e5a7f36acd84670032bc2318a3145851c',
    rewardTokenName: 'esDuck',
    rewardTokenAddress: '0xC64be5d1bA7Ce5Bc9Da0e6faBCf97039ffDFCA9e',
    stakeAddress: '0xa43675427916b10c9ABE9318a380F6F763F988bf',
  },
  {
    stakeTokenName: 'USDC-cDuck',
    stakeTokenAddress: '0x4a439095104bb3d9c7354129247ca2aaf8b80046',
    rewardTokenName: 'esDuck',
    rewardTokenAddress: '0xC64be5d1bA7Ce5Bc9Da0e6faBCf97039ffDFCA9e',
    stakeAddress: '0x769830fa0f4a6ab8df2D11FA1741101D0BF8ee43',
  },
];
