import globalStore from '@/stores/global';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import config from '@/config/config';
import light from '@/assets/light.png';
import dark from '@/assets/dark.png';
import { changeThemeVal } from '@/App';
import { switchNetwork, Wallet, WalletEnum } from '@/utils/wallet';
import useIsMobile from '@/hooks/useIsMobile';
import { connectEagerlyManager, useWeb3 } from '@/web3';
import { NoSafeContext } from '@web3-react/gnosis-safe';
import WalletDetail from '../../WalletDetail.ts';
import WalletModal, { Network } from '../../WalletModal';
import styles from './style.module.scss';
import { networkConfigMap } from '../../../config/network';

const ConnectWallet: React.FC = () => {
  const { modalVisible } = globalStore();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const { isActive, account, chainId, connectors, connector } = useWeb3();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));

  const connectWallet = () => {
    globalStore.setState({
      modalVisible: true,
    });
  };

  const disconnectWallet = () => {
    setDetailModalVisible(true);
  };

  const handleSelectNetworkAndWallet = async (network: Network, wallet: Wallet) => {
    if (!connectors) return;
    if (network.chainId !== chainId) {
      await switchNetwork(network.chainId);
      globalStore.setState({
        modalVisible: false,
      });
    }

    /**
     * @NOTE
     * 就当前的设计，只允许一个活动的钱包存在。因此一旦某一个钱包激活需要确保断开旧的钱包
     */
    const activate = async () => {
      if (wallet.name === WalletEnum.gnosis) {
        if (!connectors.GnosisSafe.connector.sdk?.safe) throw new NoSafeContext();
        if (connector !== connectors.GnosisSafe.connector) await connector?.deactivate();
        await connectors.GnosisSafe.connector.activate();
        return;
      }
      if (wallet?.name === WalletEnum.bitkeep) {
        // @TODO: provide by typed module
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!window?.ethereum?.isBitKeep) {
          window.open('https://bitkeep.com/');
          return;
        }
        if (connector !== connectors.GnosisSafe.connector) await connector?.deactivate();
        await connectors.MetaMask.connector.activate(networkConfigMap[network.chainId]);
        return;
      }
      if (wallet.name === WalletEnum.metamask) {
        if (connector !== connectors.MetaMask.connector) await connector?.deactivate();
        await connectors.MetaMask.connector.activate(networkConfigMap[network.chainId]);
        return;
      }
      // if (wallet.name === WalletEnum.walletconnect) {
      //   if (connector !== connectors.WalletConnect.connector) await connector?.deactivate();
      //   await connectors.WalletConnect.connector.activate(network.chainId);
      //   return;
      // }
      await connector?.activate();
    };

    try {
      await activate();
      connectEagerlyManager.unsuppress();
    } catch (error) {
      console.error(error);
      /**
       * 默认用户是看到 Gnosis 是会用的，因此如果主动点击了 Gnosis 后发现不再对应沙箱环境中才跳转引导进入沙箱
       */
      if (wallet.name === WalletEnum.gnosis && error instanceof NoSafeContext) {
        window.open('https://gnosis-safe.io/app');
        return;
      }
    } finally {
      globalStore.setState({
        modalVisible: false,
      });
    }
  };

  const formatAccount = (accountStr: string) => `${accountStr.slice(0, 6)}...${accountStr.slice(-4)}`;

  const isMobile = useIsMobile();

  return (
    <>
      <section className={styles.WalletArea}>
        {isActive && account && chainId ? (
          <div className={styles.connectedDiv} onClick={disconnectWallet}>
            <div className={styles.account}>{formatAccount(account)}</div>
            <div className={styles.network}>{config.NETWORK_NAME}</div>
          </div>
        ) : (
          <div className={styles.connectDiv}>
            <button type="button" className={styles.button} onClick={connectWallet}>
              {isMobile ? 'Connect' : 'Connect Wallet'}
            </button>
          </div>
        )}
        <img
          className={styles.themeChange}
          alt=""
          src={theme === 'light' ? dark : light}
          onClick={() => {
            changeThemeVal();
            setTheme(localStorage.getItem('theme'));
          }}
        />
      </section>
      {modalVisible && (
        <Modal
          wrapClassName="customize-modal"
          maskClosable
          onCancel={() => {
            // setModalVisible(false);
            globalStore.setState({
              modalVisible: false,
            });
          }}
          visible
          title={null}
          footer={null}
          style={{ top: 200 }}>
          <WalletModal
            closeModal={() => {
              // setModalVisible(false);
              globalStore.setState({
                modalVisible: false,
              });
            }}
            onSelectNetworkAndWallet={handleSelectNetworkAndWallet}
          />
        </Modal>
      )}

      <Modal
        wrapClassName="customize-modal"
        maskClosable
        onCancel={() => setDetailModalVisible(false)}
        visible={detailModalVisible}
        title={null}
        footer={null}
        width={600}
        style={{ top: 200 }}>
        <WalletDetail closeModal={() => setDetailModalVisible(false)} />
      </Modal>
    </>
  );
};

export default ConnectWallet;
