import React from 'react';
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import styles from './style.module.scss';
import ScrollToTop from './components/ScrollToTop';
import BuyCoin from './components/BuyCoin';
import { useWeb3 } from './web3';
import ProCloud from './pages/ProCloud';
import ProCloudStake from './pages/ProCloudStake';

const AppInner: React.FC = () => {
  const { account } = useWeb3();

  const hideLeftNavigation = true;

  return (
    <section className={styles.AppWrapper} style={hideLeftNavigation ? { background: 'black' } : {}}>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        {/* <Banner /> */}
        <section className={styles.BodyWrapper}>
          <section className={styles.Container} style={hideLeftNavigation ? { padding: 0 } : {}}>
            <Routes>
              <Route path="/" element={<Navigate to="/duet-pro-cloud-Stake" />} />
              <Route path="/duet-pro-cloud" element={<ProCloud />} />
              <Route path="/duet-pro-cloud-Stake" element={<ProCloudStake />} />
            </Routes>
            <Footer />
          </section>
        </section>
        <BuyCoin />
      </BrowserRouter>
    </section>
  );
};

export default AppInner;
