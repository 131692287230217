import classnames from 'classnames';
import { Wallet, WalletEnum } from '@/utils/wallet';
import React, { useEffect, useState } from 'react';
import config from '@/config/config';
import FlexRow from '../FlexRow';
import Margin from '../Margin';
import styles from './style.module.scss';

export interface Network {
  logo: string;
  name: string;
  chainId: number;
}

const walletList: Wallet[] = [
  {
    logo: '#icon-a-MetaMasklogo',
    name: WalletEnum.metamask,
  },
  // {
  //   logo: '#icon-a-walletconnnect',
  //   name: WalletEnum.walletconnect,
  // },
  {
    logo: '#icon-bitkeep',
    name: WalletEnum.bitkeep,
  },
  /**
   * https://duetfi.atlassian.net/browse/DUET-45
   * 严格来说不是钱包，但作为钱包安全的一种代理方案放在这里可以作为引导
   */
  {
    logo: '#icon-gnosis',
    name: WalletEnum.gnosis,
  },
];

console.log('config', config);

const networks: Network[] = [
  {
    logo: '#icon-BSC',
    name: config.NETWORK_NAME,
    chainId: config.mainChainId,
  },
];

interface WalletModalProps {
  closeModal: () => void;
  onSelectNetworkAndWallet: (network: Network, wallet: Wallet) => void;
}

const WalletModal: React.FC<WalletModalProps> = props => {
  const { closeModal, onSelectNetworkAndWallet } = props;

  const [currentNetwork, setCurrentNetwork] = useState<Network>();
  const [currentWallet, setCurrentWallet] = useState<Wallet>();

  const onSelectNetwork = (network: Network) => {
    setCurrentNetwork(network);
  };

  const onSelectWallet = (wallet: Wallet) => {
    setCurrentWallet(wallet);
  };

  // 设置第一个网络为默认的网络
  useEffect(() => {
    const firstNetwork = networks[0];
    setCurrentNetwork(firstNetwork);
  }, []);

  useEffect(() => {
    if (!currentNetwork || !currentWallet) return;
    onSelectNetworkAndWallet(currentNetwork, currentWallet);
  }, [currentNetwork, currentWallet]);

  return (
    <div>
      <section className={styles.WalletModalContent}>
        <div className={styles.header}>
          <span className={styles.title}>Connect Wallet</span>
          <svg className={styles.icon} aria-hidden="true" onClick={closeModal}>
            <use xlinkHref="#icon-guanbi2" />
          </svg>
        </div>

        <div>
          <div className={styles.listTitle}>1. Choose Network</div>
          <Margin top={15} />
          {networks.map(network => (
            <div
              key={network.name}
              className={classnames(styles.network, {
                [styles.active]: currentNetwork?.name === network.name,
              })}
              onClick={() => onSelectNetwork(network)}>
              <FlexRow direction="column">
                <div className={styles.networkLogoOutline}>
                  <svg className={classnames(styles.icon, styles.networkLogo)} aria-hidden="true">
                    <use xlinkHref={network.logo} />
                  </svg>
                </div>
                <Margin top={5} />
                <span className={styles.networkName}>{network.name}</span>
              </FlexRow>
            </div>
          ))}
        </div>

        <Margin top={20} />

        <div>
          <div className={styles.listTitle}>2. Choose Wallet</div>
          <Margin top={15} />
          <div className={styles.walletList}>
            {walletList.map(wallet => (
              <div
                key={wallet.name}
                className={classnames(styles.wallet, {
                  [styles.active]: currentWallet?.name === wallet.name,
                })}
                onClick={() => onSelectWallet(wallet)}>
                <FlexRow direction="column">
                  <div className={styles.walletLogoOutline}>
                    <svg className={classnames(styles.icon, styles.walletLogo)} aria-hidden="true">
                      <use xlinkHref={wallet.logo} />
                    </svg>
                  </div>
                  <Margin top={5} />
                  <span className={styles.walletName}>{wallet.name}</span>
                </FlexRow>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WalletModal;
