import React, { useState } from 'react';
import styled from 'styled-components';
import DuetLogoMobile from '@/assets/duet-logo-mobile.png';
import DuetLogoMobileLight from '@/assets/duet-logo-mobile-light.png';
import useIsMobile from '@/hooks/useIsMobile';
import cs from 'classnames';
import globalStore from '@/stores/global';
import { Theme } from '@/assets/theme';
import logo from '@/assets/pro-cloud/logo.svg';
import ConnectWallet from './components/ConnectWallet';
import FlexRow from '../FlexRow';
import Margin from '../Margin';
import styles from './components/style.module.scss';

const Header: React.FC = () => {
  const { dataTheme } = globalStore();
  const [showMenu, setShowMenu] = useState(false);

  const hideLeftNavigation = true;

  return (
    <div>
      <HeaderWrapper>
        {hideLeftNavigation ? (
          <LogoArea>
            <DuetLogo src={logo} alt="logo" />
            <a style={{ textDecoration: 'underline', marginLeft: 20 }} target="_blank" href="/" rel="noreferrer">
              Duet V1
            </a>
            <a
              style={{ textDecoration: 'underline', marginLeft: 20 }}
              target="_blank"
              href="https://pro.duet.finance"
              rel="noreferrer">
              Duet Pro
            </a>
          </LogoArea>
        ) : (
          <div />
        )}
        <FlexRow alignItems="center">
          {/* <DuetPrice /> */}
          <ConnectWallet />
        </FlexRow>
      </HeaderWrapper>

      <HeaderWrapperMobile>
        <img
          className="duet-logo-mobile"
          src={dataTheme === Theme.dark ? DuetLogoMobile : DuetLogoMobileLight}
          alt=""
        />
        <FlexRow>
          <ConnectWallet />
          <Margin left={10} />
          <i className={cs('iconfont icon-baseline-menu-px', styles.menuIcon)} onClick={() => setShowMenu(true)} />
        </FlexRow>
      </HeaderWrapperMobile>
    </div>
  );
};

export default Header;

const LogoArea = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;

  a {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }
`;
const DuetLogo = styled.img`
  height: 40px;
  /* cursor: pointer; */
`;

const HeaderWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  height: 110px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0);
`;

const HeaderWrapperMobile = styled.div`
  @media (min-width: 769px) {
    display: none;
  }

  padding-top: 40px;

  .duet-logo-mobile {
    width: 83x;
    height: 30px;
  }

  .menu-icon {
    font-size: 30px;
    color: white;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
